import React, { useState, useEffect } from "react";
import lg from "../../images/Light logo.png";
import bg from "../../images/registerimg.png";
import { useNavigate } from "react-router-dom";
import SubmitSuccess from "../StudentsDashboard/Assignment/SubmitSuccess";
import {
  registerUser,
  validateEmail,
  checkExistingStudent,
  registerTutor,
} from "../../services/authServices";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../services/url";
import { Circles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { SET_USER1 } from "../../redux/auth/authSlice";
import CustomModal from "../StudentsDashboard/Modal";
import { fetchCohorts } from "../.././services/cohortService.js";


const initialData = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  phoneNumber: "",
  selectedCourse: "",
  selectedCohortId: "",
};

const RegisterTutor = ({ closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [cohortOptions, setCohortOptions] = useState([]);

  const text = "Tutor created Successfully";
  const desc = `Username:${credentials.username}\nPassword: ${credentials.randomPassword}`;
  const buttonText = "Go Home";
  const link = "/studentdashboard";

  const navigateToPage = (path) => {
    navigate(path);
    // setNav(false); // Close the menu after navigation
  };

  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const { firstName, lastName, email, country, phoneNumber, selectedCourse } =
    formData;
  const [courseOptions, setCourseOptions] = useState([]);
  const [errors, setErrors] = useState({});

  //fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(
          `${BACKEND_URL}/course/fetch-courses`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (response.status >= 200 && response.status < 300) {
          setCourseOptions(response.data);
        } else {
          console.error(
            response.data?.error || "Failed to fetch courses. Please try again."
          );
        }
      } catch (error) {
        // Handle errors
        const errorMessage =
          error.response?.data?.error ||
          "An error occurred while fetching courses";
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let error = {};
    switch (name) {
      case "firstName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            firstName: "First name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            firstName: "First name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.firstName = "";
        }
        break;
      case "lastName":
        if (!/^[a-zA-Z]+$/.test(value)) {
          error = {
            ...error,
            lastName: "Last name should only contain letters",
          };
        } else if (value.length < 3) {
          error = {
            ...error,
            lastName: "Last name should be at least 3 characters long",
          };
        } else {
          // Remove the error if the input is valid
          error.lastName = "";
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          error = { ...error, email: "Invalid email address" };
        } else {
          // Remove the error if the input is valid
          error.email = "";
        }
        break;
      case "phoneNumber":
        if (value.length < 10) {
          error = {
            ...error,
            phoneNumber: "phoneNumber should be at least 10 characters long",
          };
        } else {

          error.phoneNumber = "";
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, ...error });
  };
  const isSubmitDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !country ||
    !phoneNumber ||
    !selectedCourse;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !country ||
      !selectedCourse
    ) {
      return toast.error("All fields are required");
    }
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }
    setIsLoading(true);
    try {
      const userData = {
        firstName,
        lastName,
        email,
        country,
        phoneNumber,
        selectedCourse,
        cohortId: formData.selectedCohortId,
      };

      const response = await registerTutor(userData);

      if (response.status >= 200 && response.status < 300) {
        const { username, randomPassword } = response.data;
        setCredentials({ username, randomPassword });
        setIsOpen(true);
      }
      return response.data;
    }

    catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const countryCodes = [
    {
      name: "Niger",
      code: "+227",
      shortForm: "NER",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Nigeria",
      code: "+234",
      shortForm: "NG",
      currencyCode: "NGN",
      currencySymbol: "₦",
    },
    {
      name: "Rwanda",
      code: "+250",
      shortForm: "RWA",
      currencyCode: "RWF",
      currencySymbol: "FRw",
    },
    {
      name: "Sao Tome and Principe",
      code: "+239",
      shortForm: "STP",
      currencyCode: "STN",
      currencySymbol: "Db",
    },
    {
      name: "Senegal",
      code: "+221",
      shortForm: "SEN",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Seychelles",
      code: "+248",
      shortForm: "SYC",
      currencyCode: "SCR",
      currencySymbol: "₨",
    },
    {
      name: "Sierra Leone",
      code: "+232",
      shortForm: "SLE",
      currencyCode: "SLL",
      currencySymbol: "Le",
    },
    {
      name: "Somalia",
      code: "+252",
      shortForm: "SOM",
      currencyCode: "SOS",
      currencySymbol: "Sh",
    },
    {
      name: "South Africa",
      code: "+27",
      shortForm: "ZAF",
      currencyCode: "ZAR",
      currencySymbol: "R",
    },
    {
      name: "South Sudan",
      code: "+211",
      shortForm: "SSD",
      currencyCode: "SSP",
      currencySymbol: "£",
    },
    {
      name: "Sudan",
      code: "+249",
      shortForm: "SDN",
      currencyCode: "SDG",
      currencySymbol: "ج.س.",
    },
    {
      name: "Tanzania",
      code: "+255",
      shortForm: "TZA",
      currencyCode: "TZS",
      currencySymbol: "TSh",
    },
    {
      name: "Togo",
      code: "+228",
      shortForm: "TGO",
      currencyCode: "XOF",
      currencySymbol: "CFA",
    },
    {
      name: "Tunisia",
      code: "+216",
      shortForm: "TUN",
      currencyCode: "TND",
      currencySymbol: "د.ت",
    },
    {
      name: "Uganda",
      code: "+256",
      shortForm: "UGA",
      currencyCode: "UGX",
      currencySymbol: "USh",
    },
    {
      name: "Zambia",
      code: "+260",
      shortForm: "ZMB",
      currencyCode: "ZMW",
      currencySymbol: "ZK",
    },
    {
      name: "Zimbabwe",
      code: "+263",
      shortForm: "ZWE",
      currencyCode: "ZWL",
      currencySymbol: "Z$",
    },
    {
      name: "United States",
      code: "+1",
      shortForm: "US",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "United Kingdom",
      code: "+44",
      shortForm: "GB",
      currencyCode: "GBP",
      currencySymbol: "£",
    },
    {
      name: "Germany",
      code: "+49",
      shortForm: "DEU",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "France",
      code: "+33",
      shortForm: "FRA",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Italy",
      code: "+39",
      shortForm: "ITA",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Spain",
      code: "+34",
      shortForm: "ESP",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Netherlands",
      code: "+31",
      shortForm: "NLD",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Belgium",
      code: "+32",
      shortForm: "BEL",
      currencyCode: "EUR",
      currencySymbol: "€",
    },
    {
      name: "Sweden",
      code: "+46",
      shortForm: "SWE",
      currencyCode: "SEK",
      currencySymbol: "kr",
    },
    {
      name: "Norway",
      code: "+47",
      shortForm: "NOR",
      currencyCode: "NOK",
      currencySymbol: "kr",
    },
    {
      name: "Canada",
      code: "+1",
      shortForm: "CAN",
      currencyCode: "CAD",
      currencySymbol: "",
    },
    {
      name: "Mexico",
      code: "+52",
      shortForm: "MEX",
      currencyCode: "MXN",
      currencySymbol: "",
    },
    {
      name: "Brazil",
      code: "+55",
      shortForm: "BRA",
      currencyCode: "BRL",
      currencySymbol: "R",
    },
    {
      name: "Argentina",
      code: "+54",
      shortForm: "ARG",
      currencyCode: "ARS",
      currencySymbol: "",
    },
    {
      name: "Chile",
      code: "+56",
      shortForm: "CHL",
      currencyCode: "CLP",
      currencySymbol: "",
    },
    {
      name: "Colombia",
      code: "+57",
      shortForm: "COL",
      currencyCode: "COP",
      currencySymbol: "",
    },
    {
      name: "Peru",
      code: "+51",
      shortForm: "PER",
      currencyCode: "PEN",
      currencySymbol: "S/",
    },
    {
      name: "Venezuela",
      code: "+58",
      shortForm: "VEN",
      currencyCode: "VES",
      currencySymbol: "Bs.S",
    },
    {
      name: "Ecuador",
      code: "+593",
      shortForm: "ECU",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "Bolivia",
      code: "+591",
      shortForm: "BOL",
      currencyCode: "BOB",
      currencySymbol: "Bs",
    },
    {
      name: "Paraguay",
      code: "+595",
      shortForm: "PRY",
      currencyCode: "PYG",
      currencySymbol: "₲",
    },
    {
      name: "Uruguay",
      code: "+598",
      shortForm: "URY",
      currencyCode: "UYU",
      currencySymbol: "U",
    },
    {
      name: "Panama",
      code: "+507",
      shortForm: "PAN",
      currencyCode: "PAB",
      currencySymbol: "B/.",
    },
    {
      name: "Costa Rica",
      code: "+506",
      shortForm: "CRI",
      currencyCode: "CRC",
      currencySymbol: "₡",
    },
    {
      name: "Puerto Rico",
      code: "+1",
      shortForm: "PRI",
      currencyCode: "USD",
      currencySymbol: "</span>",
    },
    {
      name: "Dominican Republic",
      code: "+1",
      shortForm: "DOM",
      currencyCode: "DOP",
      currencySymbol: "RD$",
    },
    {
      name: "Jamaica",
      code: "+1",
      shortForm: "JAM",
      currencyCode: "JMD",
      currencySymbol: "J$",
    },
    {
      name: "Trinidad and Tobago",
      code: "+1",
      shortForm: "TTO",
      currencyCode: "TTD",
      currencySymbol: "TT$",
    },
    {
      name: "Barbados",
      code: "+1",
      shortForm: "BRB",
      currencyCode: "BBD",
      currencySymbol: "",
    },
    {
      name: "Bahamas",
      code: "+1",
      shortForm: "BHS",
      currencyCode: "BSD",
      currencySymbol: "</span>",
    },
    {
      name: "Belize",
      code: "+501",
      shortForm: "BLZ",
      currencyCode: "BZD",
      currencySymbol: "BZ$",
    },
    {
      name: "Honduras",
      code: "+504",
      shortForm: "HND",
      currencyCode: "HNL",
      currencySymbol: "L",
    },
    {
      name: "El Salvador",
      code: "+503",
      shortForm: "SLV",
      currencyCode: "USD",
      currencySymbol: "",
    },
    {
      name: "Guatemala",
      code: "+502",
      shortForm: "GTM",
      currencyCode: "GTQ",
      currencySymbol: "Q",
    },
    {
      name: "Nicaragua",
      code: "+505",
      shortForm: "NIC",
      currencyCode: "NIO",
      currencySymbol: "C</span>",
    },
    {
      name: "Japan",
      code: "+81",
      shortForm: "JPN",
      currencyCode: "JPY",
      currencySymbol: "¥",
    },
    {
      name: "China",
      code: "+86",
      shortForm: "CHN",
      currencyCode: "CNY",
      currencySymbol: "¥",
    },
    {
      name: "India",
      code: "+91",
      shortForm: "IND",
      currencyCode: "INR",
      currencySymbol: "₹",
    },
    {
      name: "South Korea",
      code: "+82",
      shortForm: "KOR",
      currencyCode: "KRW",
      currencySymbol: "₩",
    },
    {
      name: "Australia",
      code: "+61",
      shortForm: "AUS",
      currencyCode: "AUD",
      currencySymbol: "",
    },
    {
      name: "New Zealand",
      code: "+64",
      shortForm: "NZL",
      currencyCode: "NZD",
      currencySymbol: "</span>",
    },
  ];



  useEffect(() => {
    const initializeCohorts = async () => {
      try {
        const cohorts = await fetchCohorts();
        setCohortOptions(cohorts);
      } catch (error) {
        console.error("Error fetching cohorts:", error);
      }
    };
    initializeCohorts();
  }, []);

  // Format cohort dates to display in the select dropdown
  const formatCohortName = (cohort) => {
    const startDate = new Date(cohort.start_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const endDate = new Date(cohort.end_date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return `Cohort ${cohort.id}: ${startDate} to ${endDate}`;
  };


  return (
<div className="w-full mb-20 py-10 flex justify-center gap-15 mb-12">

      <div className="bg-white w-full max-w-3xl rounded-lg shadow-lg">
        {/* Header */}
        <div className="px-8 py-6 flex items-center justify-between">
          <h2 className="text-2xl font-semibold text-900">Create Tutor Login</h2>
          <button onClick={closeModal}>
            <img
              alt="Close"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b8bfb4b3ce7647f9a23e97a34d4ad17c052f0d02760309baa86ec9d8edabc94a?apiKey=c4f2754934754c86a2ca35b496fd31f4&"
              className="shrink-0 w-6 h-6"
            />
          </button>
        </div>
  
        {/* Form */}
        <form className="px-8 py-6 space-y-6" onSubmit={handleSubmit}>
          {/* First and Last Name */}
          <div className="flex gap-6">
            <div className="flex flex-col flex-1">
              <label htmlFor="firstName" className="font-semibold text-gray-700">First Name</label>
              <input
                type="text"
                id="firstName"
                placeholder="First Name"
                value={firstName}
                name="firstName"
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
              />
              {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="lastName" className="font-semibold text-gray-700">Last Name</label>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name"
                value={lastName}
                name="lastName"
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
              />
              {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
            </div>
          </div>
  
          {/* Email */}
          <div className="flex flex-col">
            <label htmlFor="email" className="font-semibold text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              placeholder="example@domain.com"
              value={email}
              name="email"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
  
          {/* Where did you get to know about us? */}
          <div className="flex flex-col">
            <label htmlFor="referredFrom" className="font-semibold text-gray-700">
              Where did you get to know about us?
            </label>
            <select
              name="referredFrom"
              id="referredFrom"
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
            >
              <option value="" disabled>Select an option</option>
              <option value="google">Google</option>
              <option value="friend">Friend</option>
              <option value="social-media">Social Media</option>
              <option value="other">Other</option>
            </select>
          </div>
  
          {/* Country and Phone Number */}
          <div className="flex gap-6">
            <div className="flex flex-col flex-1">
              <label htmlFor="country" className="font-semibold text-gray-700">Country</label>
              <select
                name="country"
                id="country"
                value={country}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
              >
                <option value="" disabled>Select Country</option>
                {countryCodes.map((country, index) => (
                  <option key={index} value={`${country.shortForm}-${country.code}-${country.currencyCode}`}>
                    {`${country.shortForm} ${country.code}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="phoneNumber" className="font-semibold text-gray-700">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                name="phoneNumber"
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
              />
              {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
            </div>
          </div>
  
          {/* Select Course */}
          <div className="flex flex-col">
            <label htmlFor="selectedCourse" className="font-semibold text-gray-700">Select Course</label>
            <select
              name="selectedCourse"
              id="selectedCourse"
              value={selectedCourse}
              onChange={handleInputChange}
              className="border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring focus:ring-blue-200"
            >
              <option value="" disabled>Select an option</option>
              {courseOptions.map((option) => (
                <option key={option.id} value={option.courseName}>
                  {option.courseName}
                </option>
              ))}
            </select>
          </div>
  

          <div className="flex flex-col mb-12">
            <label htmlFor="selectedCohortId" className="font-semibold text-gray-700">Select Cohort</label>
            <div className="flex flex-col gap-2 mt-4 mb-9">
              {cohortOptions.map((cohort) => (
                <label key={cohort.id} className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="radio"
                    name="selectedCohortId"
                    value={cohort.id}
                    checked={formData.selectedCohortId === cohort.id.toString()}
                    onChange={handleInputChange}
                    className="shrink-0 w-4 h-4 rounded-full border-2 border-gray-400"
                  />
                  <span className="text-gray-700">{formatCohortName(cohort)}</span>
                </label>
              ))}
            </div>
          </div>
  
          {/* Submit Button */}
          <div className="flex flex-col">
            <button
              type="submit"
              className={`py-2 px-4 rounded-md text-white ${isSubmitDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} `}
              disabled={isSubmitDisabled}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Circles height="20" width="20" color="#fff" ariaLabel="circles-loading" visible={true} />
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
      {isOpen && (
        <CustomModal isOpen={isOpen}>
          <SubmitSuccess
            closeModal={() => setIsOpen(false)}
            text={text}
            desc={desc}
            link={link}
            buttonText={buttonText}
            handleButtonClick={() => closeModal()}
          />
        </CustomModal>
      )}
    </div>
  );
  
};

export default RegisterTutor;
